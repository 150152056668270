import web3 from './web3';
import './App.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BigNumber from "bignumber.js";
import abi from './abi';
import PhaseBar from './PhaseBar';
import {whitelist,ogslist} from './whitelist';
import Loader from "react-loader-spinner";
  
const address = process.env.REACT_APP_CONTRACT_ADDRESS!="NONE"?process.env.REACT_APP_CONTRACT_ADDRESS:null;
const maxPerMint = Number(process.env.REACT_APP_MAX_PER_MINT || 4);
const maxPerMintPresale = Number(process.env.REACT_APP_PRESALE_MAX_MINT || 4);
const allowedNetwork = Number(process.env.REACT_APP_WEB3_NETWORK || 1);
const OG = process.env.REACT_APP_OGS!="NONE"?true:null;

function Mint(props) {
    const [minted, setMinted] = useState(null)
    const [sellout, setSellout] = useState(null)
    const [state, setState] = useState(null)
    const [mintCount, setMintCount] = useState(1)
    const [totalSupply, setTotalSupply] = useState(null)
    const [txHash,setTxHash] = useState(null)
    const [errorState,setErrorState] = useState(null)
    const [account,setAccount] = useState(null)
    

    console.log(`Contract Address ${address}`);
    var contract = address ? new web3.eth.Contract(abi, address):null;


    useEffect(()=>{
      if(state)
        setSellout(totalSupply==8888);
    },[account,totalSupply])

    useEffect(()=>{
      (async ()=>{
        await refresh();
      })();
    },[])

    function getPrice() {
      if(state==1 && OG)return new BigNumber('64000000000000000');
      if(state==1 && !OG)return new BigNumber('72000000000000000');
      return new BigNumber('80000000000000000');//80000000000000000
    }

    async function connectMetamask() {
      try{
        if (typeof window.ethereum === 'undefined'){
          const e = new Error("Failed to connect to metamask.");
          setErrorState(e);
          return;
        }
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
        
      }catch(e){
        console.error(e);
        setErrorState(e);
      }
    }

    async function refresh(){
        if(!contract)return;
        try{
          const network = await web3.eth.net.getId();
          if(network != allowedNetwork)
            throw new Error("Please switch to Ethereum Mainnet.");
          // const pr = await contract.methods.price().call();
          // setPrice(pr);
          // console.log("Price fetched "+pr)
          const ts = await contract.methods.totalSupply().call();
          console.log("Total Supply fetched"+ts)
          setTotalSupply(ts);
          const st = await contract.methods.state().call();
          console.log("State fetched "+st)
          setState(st);
          return {price:getPrice(),totalSupply:ts,state:st};
        }catch(e){
          console.error(e);
          setErrorState(e);
        }
    }

    async function onTxHash(data){
      setTxHash(data)
    }
    function mintComplete(){
      return <div className='mintstatus'><a target="_blank"  rel="noreferrer" href={`https://etherscan.io/tx/${minted.data.transactionHash}`}>Mint Successful ✔<></></a><br/></div>
    }
    
    async function onError(e){
      setMinted({status:-1,data:e});
      setErrorState(e)
    }
    async function onReceipt(res){
      // console.dir(res.events.Transfer.returnValues)
      let mintedTokens = [];
      if(Array.isArray(res.events.Transfer))
        mintedTokens = res.events.Transfer.map(x => x.returnValues.tokenId)
      else mintedTokens.push(res.events.Transfer.returnValues.tokenId)
      // console.log("tokenids minted " + mintedTokens)
      // console.dir(res)
      if(res && res.transactionHash){ 
        console.log("Minted!",mintedTokens.length);
        setMinted({status:1,data:res,tokens:mintedTokens});
        // todo show minted tokens
      }else{
        console.error("Something went wrong!")
        setMinted({status:-1,data:res});
      }
    }
    async function mint(count){
        if(isNaN(count) || count<=0 || count>maxMint()){
          console.error(`Cannot mint. Count is invalid ${count}`);
          return;
        }
        console.log("contract address " + address)
        console.log("Minting");
        // const account = await connectMetamask();
        
        if(state == 1 && OG && !ogslist.find(element => element.toLowerCase() == account.toLowerCase()))
        {
          const e = new Error(`Address ${account} is not in OGs list!`);
          console.error(e);
          setErrorState(e);
          setMinted({status:-1,data:e})
          return;
        }
        
        if(state == 1 &&
           !OG &&
           !whitelist.find(element => element.toLowerCase() == account.toLowerCase()) &&
           !ogslist.find(element => element.toLowerCase() == account.toLowerCase()))
        {
          const e = new Error(`Address ${account} is not in whitelist!`);
          console.error(e);
          setErrorState(e);
          setMinted({status:-1,data:e})
          return;
        }

        if(!contract)
            contract = new web3.eth.Contract(abi, address);
        // console.dir(contract);
        console.log({account});

        if(contract){
            setMinted({status:0,data:null});
            const finalPrice = computeFinalPrice(getPrice(),count);
            try{
              var txn;
              if(state == 1){
                txn = contract.methods.mintPresale(count).send({from:account,value:finalPrice.toString()});
              }
              else if(state == 2)
                txn = contract.methods.mint(count).send({from:account,value:finalPrice.toString()});

              txn.on('transactionHash', onTxHash).on('receipt',onReceipt).on('error', onError);
          }catch(e){
            console.error(e);
            console.dir(e);
            setErrorState(e);
            setMinted({status:-1,data:e})
          }
        }

    }
    function computeFinalPrice(p,c){
      if(c==4)
        return p.multipliedBy(3).plus(p.dividedBy(2));
      else return p.multipliedBy(c);
    }
    function getDiscountString(p,c){
      if(c!=4)return <></>;
      else return <strike><small>{`${web3.utils.fromWei(String(p*4), 'ether')}`} Ξ </small></strike>;
    }
    function maxMint(){
      if(state == 1)return maxPerMintPresale;
      return maxPerMint;
    }
    function changeMintcount(dir){
      setMintCount(Math.max(1,Math.min(maxMint(),mintCount+dir)));
    }
    function mintReady(){
      if(totalSupply && totalSupply>=10000)return <p>All Tokens Minted!</p>;
    
      return <><button id="small" onClick={()=>changeMintcount(-1)}>▼</button>
      <input
      type="number"
      readOnly
      value={mintCount}
      // onChange={e => setMintCount(clamp(Number(e.target.value),1,maxMint()))}
    />
    <button id="small" onClick={()=>changeMintcount(1)}>▲</button>
      <br/>
      <button onClick={()=>mint(mintCount)}>Mint {mintCount} ➞ {web3.utils.fromWei(String(computeFinalPrice(getPrice(),mintCount)), 'ether')} Ξ {getDiscountString(getPrice(),mintCount)}</button>
      {state == 1?<><p></p><div className='text'>Presale mint for whitelisted addresses only!</div></>:<></>}
      
      </>
    }

    function mintInProgress(){
      return <div className='mintstatus'>
        <h4>Minting!</h4><Loader type="ThreeDots" color="#3f87c1" height={60} width={60} />
        </div>
    }

    function stateHeader(){
      return (<><PhaseBar data={{state}}/>
      {state == 0?<h3>Mint will be live soon! Please Wait.</h3>:<></>}
      {(state == 1 && OG) ?<h3>OGs Mint is Live!.</h3>:<></>}
      {(state == 1 && !OG) ?<h3>Whitelist Mint is Live!.</h3>:<></>}
      {state == 2?<h3>Public Mint is Live!</h3>:<></>}
      </>)
    }

    function contractAvailable(){
      if(!address)return <h3>Engines Warming...</h3>;
      if(!account)return <><button onClick={connectMetamask}>Connect Metamask</button></>
      if(sellout)return <><h3>Mint Complete!</h3></>
      if(account)
      return (<>
        {stateHeader()}
        {(state > 0) ?  mintReady():<></>}
        {(minted && minted.status === 0) ? mintInProgress():<></>}
        {(minted && minted.status === 1) ? mintComplete():<></>}
        {(minted && minted.status === -1) ? <><p>Failed to mint :(<br/></p></>:<></>}
        </>)
    }
    
  return (<>
    {contractAvailable()}
    {account && totalSupply && address && state?<div className='text'> 
    <div className="pb-wrapper">
			<div className="pb-progress-bar">
				<span className="pb-progress-bar-fill" style={{width:`${100*totalSupply/8888}%`}}></span>
			</div>
		</div>
    Minted {totalSupply}/8888 </div>:<></>}
    {txHash!=null?<div className='text'>Tx Hash : <a id="txhash" target="_blank"  rel="noreferrer" href={`https://etherscan.io/tx/${txHash}`}>{txHash.replace(/(.{12})..+/, "$1…")}</a></div>:<></>}
    {errorState!=null?<div className='err-text'>Error {errorState.code} : {errorState.message}<br/><br/>Refresh page to try again.</div>:<></>}
    </>)
}

export default Mint;

