import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react';
import Home from './Home';
import NotFound from './NotFound';
// import Resources from './Resources'

function App() {
 
  return (
    <BrowserRouter>
    <div className="App">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="*" component={NotFound} />
      </Switch>
      </div>  
    </BrowserRouter>
  );
}

export default App;
