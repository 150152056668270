// import logo from './logo.svg';
import './App.css';
import React from 'react';


require('dotenv').config()

function NotFound() {
 

  return (
    <>
     <div className="content"><a href="/">404</a></div>
    </>
    
  );
}

export default NotFound;
