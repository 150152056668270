import logo from './logo.svg';
import './App.css';
import Header from './Header';
import Mint from './Mint';
import React, { useState, useEffect } from 'react';


require('dotenv').config()

function Home() {
  const [modal, setModal] = useState(null);

  const address = process.env.REACT_APP_CONTRACT_ADDRESS;
  
  function closeModal() {
    setModal(null);
  }

  useEffect(()=>{
    console.log(`${process.env.NODE_ENV} ${address}`);
  },[address]);

  const onTokenClick = (id) => {
    setModal(id);
  }

  return (
    <>
      {/* <Header/> */}
      {/* <h1>Torque Squad</h1> */}
      <p></p>
      {/* <div className='placeholder-center'><img src="/ts_logo_full.png" width={350}></img></div> */}
      <video autoPlay muted id="bgvideo">
      <source src="https://ts-frontend-assets.s3.ap-northeast-1.amazonaws.com/bg.mp4" type="video/mp4"/>
      </video>

      <div className="content">
      {/* <img src="/ts_logo_full.png" width={350}></img> */}
      {/* <h2>Mint Site</h2> */}
      <div/>
      <Mint/>
      
      <div className='socials'>
      <a target="_blank" rel="noopener noreferrer" href={`https://discord.gg/xVuhcSZgvF`}><img alt="Discord"  src="/discord.png" width={24}></img></a>
        <a target="_blank" rel="noopener noreferrer" href={`https://www.twitter.com/torquesquad`}><img alt="Twitter"  src="/twitter.png" width={24}></img></a>
        <a target="_blank" rel="noopener noreferrer" href={`https://www.instagram.com/torquesquad.eth`}><img alt="Instagram"  src="/instagram.png" width={24}></img></a>
      </div>
      </div>
    </>
    
  );
}

export default Home;
