// import logo from './logo.svg';
import './App.css';
import React from 'react';
import { useHistory } from 'react-router';

function Header() {
  const history = useHistory();
  const location = history.location.pathname.split('/')?.pop();

  return (
      <div className="header">
      <div>{location?<a href="/">Torquesquad</a>:<a title="Torque Squad">Torque Squad</a>}</div>
      <div><ul>
        {/* <li>{location=='faq'?<>FAQ</>:<a href="/faq">FAQ</a>}</li> */}
        {/* <li>{location=='resources'?<>Resources</>:<a href="/resources">Resources</a>}</li> */}
        {/* <li><a title="Coming Soon">Resources</a></li> */}
      </ul>
      </div>
      </div>
  );
}

export default Header;
